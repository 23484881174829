/* Jameel Noori Nastaliq v4 */
/* font link */
/* https://unpkg.com/browse/jameel-noori@1.1.0/fonts/jameel-noori-nastaleeq4.woff2 */
@font-face {
  font-family: "JameelNooriNastaliq";
  src: url(https://cdn.jsdelivr.net/npm/jameel-noori/fonts/jameel-noori-nastaleeq4.woff2) format('woff2'),
       url(https://unpkg.com/jameel-noori/fonts/jameel-noori-nastaleeq4.woff2) format('woff2');
}
  /* CSS Aplly */
body {
    font-family: "JameelNooriNastaliq";
}
.post-title {
  font-family: "JameelNooriNastaliq", "Mehr", "Nafees", "UrduKhushKhatiBook", "Jameel Khushkhat-LK", "Gulzar", "Noto Nastaliq Urdu", "Amiri";
}

.post-body {
      font-family: "JameelNooriNastaliq", "Mehr", "Nafees", "UrduKhushKhatiBook", "Jameel Khushkhat-LK", "Gulzar", "Noto Nastaliq Urdu", "Amiri";
}   

